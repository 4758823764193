import React from 'react'
import { Link } from 'gatsby'

import Layout from '../layout'
import SEO from '../utils/seo'

import PageNotFoundStyles from '../assets/styles/pages/404'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404" />
        <PageNotFoundStyles>
            <h1 className="typography404">404</h1>

            <h2 className="mt-2">
                Oops! <br />A página não foi encontrada
            </h2>
            <p className="mt-1">Desculpe, mas não encontramos a página que está procurando..</p>
            <Link to="/">
                <button className="btn btn-primary btnAnimatedLink mt-3px">IR PARA HOME</button>
            </Link>
        </PageNotFoundStyles>
    </Layout>
)

export default NotFoundPage
