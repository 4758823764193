import styled from 'styled-components'

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    margin: 10vh 0;

    .typography404 {
        font-size: 150px;
        color: #d22327;

        text-shadow: 0px 0px 0 rgb(204, 29, 32), 1px 1px 0 rgb(198, 23, 26),
            2px 2px 0 rgb(191, 16, 19), 3px 3px 0 rgb(185, 10, 13), 4px 4px 0 rgb(179, 4, 7),
            5px 5px 0 rgb(173, -2, 1), 6px 6px 0 rgb(167, -8, -5), 7px 7px 0 rgb(160, -15, -12),
            8px 8px 0 rgb(154, -21, -18), 9px 9px 0 rgb(148, -27, -24),
            10px 10px 0 rgb(142, -33, -30), 11px 11px 10px rgba(57, 55, 55, 0.54),
            11px 11px 1px rgba(57, 55, 55, 0.5), 0px 0px 10px rgba(57, 55, 55, 0.2);
    }
`
